/**
 * Utility functions used site wide
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file utilityFunctions.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from "react"
import qs from 'qs'
import ApiManager from "../api/ApiManager"

/**
 * Convert plural word to singular word or vise versa
 * @link https://stackoverflow.com/a/27194360
 * @param {boolean} revert If true revert plural word to singular
 * @returns {string} Word converted to or from singular
 */
String.prototype.plural = function(revert) {
    /**
     * Pseudo Code
     *  Init plural regex obj
     *  Init singular regex obj
     *  Init irregular obj
     *  Init uncountable array
     *  If string is in the uncountable return this
     *  Init replace and patern
     *  Check for irregular forms
     *      If revert is true
     *          Change word to singular
     *      Else
     *          Change word to plural
     *      If pattern was matched
     *          return replacement
     *  If rever set array to singlar object
     *  Else set array to plural object
     *  Check for matches in array with regex
     */
    
    let plural = {
        '(quiz)$': "$1zes",
        '^(ox)$': "$1en",
        '([m|l])ouse$': "$1ice",
        '(matr|vert|ind)ix|ex$': "$1ices",
        '(x|ch|ss|sh)$': "$1es",
        '([^aeiouy]|qu)y$': "$1ies",
        '(hive)$': "$1s",
        '(?:([^f])fe|([lr])f)$': "$1$2ves",
        '(shea|lea|loa|thie)f$': "$1ves",
        'sis$': "ses",
        '([ti])um$': "$1a",
        '(tomat|potat|ech|her|vet)o$': "$1oes",
        '(bu)s$': "$1ses",
        '(alias)$': "$1es",
        '(octop)us$': "$1i",
        '(ax|test)is$': "$1es",
        '(us)$': "$1es",
        '([^s]+)$': "$1s"
    }

    let singular = {
        '(quiz)zes$': "$1",
        '(matr)ices$': "$1ix",
        '(vert|ind)ices$': "$1ex",
        '^(ox)en$': "$1",
        '(alias)es$': "$1",
        '(octop|vir)i$': "$1us",
        '(cris|ax|test)es$': "$1is",
        '(shoe)s$': "$1",
        '(o)es$': "$1",
        '(bus)es$': "$1",
        '([m|l])ice$': "$1ouse",
        '(x|ch|ss|sh)es$': "$1",
        '(m)ovies$': "$1ovie",
        '(s)eries$': "$1eries",
        '([^aeiouy]|qu)ies$': "$1y",
        '([lr])ves$': "$1f",
        '(tive)s$': "$1",
        '(hive)s$': "$1",
        '(li|wi|kni)ves$': "$1fe",
        '(shea|loa|lea|thie)ves$': "$1f",
        '(^analy)ses$': "$1sis",
        '((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': "$1$2sis",        
        '([ti])a$': "$1um",
        '(n)ews$': "$1ews",
        '(h|bl)ouses$': "$1ouse",
        '(corpse)s$': "$1",
        '(us)es$': "$1",
        's$': ""
    }

    let irregular = {
        'move': 'moves',
        'foot': 'feet',
        'goose': 'geese',
        'sex': 'sexes',
        'child': 'children',
        'man': 'men',
        'tooth': 'teeth',
        'person': 'people'
    }

    let uncountable = [
        'sheep', 
        'fish',
        'deer',
        'moose',
        'series',
        'species',
        'money',
        'rice',
        'information',
        'equipment'
    ]

    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(this.toLowerCase()) >= 0) {
        return this
    }
    let pattern, replace

    // check for irregular forms
    for (const word in irregular) {
        if (revert) {
            pattern = new RegExp(irregular[word] + '$', 'i')
            replace = word
        } 
        else { 
            pattern = new RegExp(word + '$', 'i')
            replace = irregular[word]
        }

        if (pattern.test(this)) {
            return this.replace(pattern, replace)
        }
    }

    let array

    if (revert) {
        array = singular
    }
    else {
        array = plural
    }

    // check for matches using regular expressions
    for (const reg in array) {

        pattern = new RegExp(reg, 'i')

        if (pattern.test(this)) {
            return this.replace(pattern, array[reg])
        }
    }

    return this
}

/**
 * @description Check if a value is an object
 * @param {any} objValue Any value to check if its an object 
 * @returns {boolean} true if objValue is an object, false if it isn't
 */
function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

/**
 * Capitalize first letter of string
 * @param {string} val String to capitalize first letter of 
 * @returns {string} String with the first letter capitialized
 */
function capitalizeFirstLetter(val) {
    if (Array.isArray(val)) {
        for(let i = 0 ; i < val.length ; i++) {
            val[i] = val[i].charAt(0).toUpperCase() + val[i].slice(1)
        } 
    }
    else {
        val = val.charAt(0).toUpperCase() + val.slice(1)
    }

    return val
}

/**
 * Capitalize first letter of string
 * @param {string|string[]} val String to capitalize first letter of 
 * @returns {string|string[]} String with the first letter capitialized
 */
function unCapitalizeFirstLetter(val) {
    /**
     * Pseudo Code
     *  If value is an array
     *      For each item in the array uncapitalize the first letter
     *  Else
     *      Uncapitalize the first letter of the string
     */

    if (Array.isArray(val)) {
        for(let i = 0 ; i < val.length ; i++) {
            val[i] = val[i].charAt(0).toLowerCase() + val[i].slice(1)
        } 
    }
    else {
        val = val.charAt(0).toLowerCase() + val.slice(1)
    }

    return val
}

/**
 * @description Check if a value is a boolean
 * @param {any} val Any value to check if its an boolean 
 * @returns {boolean} true if value is a boolean, false if it isn't
 */
function isBoolean(val) {
    return val === false || val === true
}

/**
 * Takes in date in milliseconds and which parts you want to show for a readable date
 * @param {number} date Date in milliseconds
 * @param {object} parts Parts to show of date string
 * @param {boolean} parts.weekday If false don't show weekday, default true
 * @param {boolean} parts.year If false don't show year, default true
 * @param {boolean} parts.month If false don't show month, default true
 * @param {boolean} parts.day If false don't show day, default true
 * @param {boolean} parts.hour If false don't show hour, default true
 * @param {boolean} parts.minute If false don't show minute, default true
 * @param {boolean} parts.at If false dont show "<date> at <time>" instead "<date>, <time>"
 * @param {Intl.DateTimeFormat} formatter Custom formatter
 * @returns {string} Date in readable format
 */
function timeStampToReadable(date, {weekday = true, year = true, month = true, day = true, hour = true, minute = true, at = false} = {}, formatter = null) {
    // If formatter is passed
    if (formatter !== null) {
        return formatter.format(date)
    }

    let options = { 
        ...((weekday !== false) ? {weekday: (weekday === true ? 'long' : weekday)} : {}), 
        ...((year === true) ? {year: 'numeric'} : {}), 
        ...((month === true) ? {month: 'short'} : {}), 
        ...((day === true) ? {day: 'numeric'} : {}), 
        ...((hour === true) ? {hour: 'numeric'} : {}), 
        ...((minute === true) ? {minute: "numeric"} : {}) 
    }
    
    let dateString = new Date(date).toLocaleDateString(navigator.language, options)
    if (at === false) {
        return dateString.replace(' at ', ', ')
    }
    else {
        return dateString.substring(0, dateString.lastIndexOf(", ")) + ' at ' + dateString.substring(dateString.lastIndexOf(", ") + 1, dateString.length)
    }
}

/**
 * Creates a new Intl.DateTimeFormat
 * @param {Intl.DateTimeFormat} options  options
 * @param {'standard' | 'military'} hourCycle hourCycle
 * @returns {Intl.DateTimeFormat}
 */
function createFormatter(options, hourCycle = null) {
    return new Intl.DateTimeFormat(navigator.language, {
        ...options, 
        ...(hourCycle !== null && {hour12: hourCycle === 'standard' ? true : false})
    })
}

/**
 * Takes time in milliseconds and converts it to locale time not including the date
 * @param {number} date Date in milliseconds
 * @returns {string} Date with time only
 */
function timeStampToReadableTime(date) {
    let localeSpecificTime = new Date(date).toLocaleTimeString()
    return localeSpecificTime.replace(/:\d+ /, ' ')
}

/**
 * Convert ISO datetime to utc ms
 * @param {Date} date ISO date
 * @returns {number} date in utc ms
 */
function isoTimeToUtcMs(date) {
    let myDate = new Date(date)
    return myDate.getTime()
}

/**
 * Takes in date in milliseconds and converts to yyyy-mm-dd
 * @param {number} date Date in milliseconds
 * @returns {string} Date in format yyyy-mm-dd
 */
function formatDate(date) {
    /**
     * Psuedo Code
     *  Split string extract date
     *  Get date with timezone offset
     *  Split ISO string and return date only
     */
    date.toISOString().split('T')[0]
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    return date.toISOString().split('T')[0]
}

/**
 * Check if the current users language should use 12 hour time or not
 * @returns True or false
 */
function is12Hour() {
    let hourSetting = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hourCycle

    if (hourSetting === 'h12') {
        return true
    } 
    else {
        return false
    }
}

/**
 * Takes in a route and returns its friendly name
 * @param {string} route Page route 
 * @returns {string[]} Array of names of a page and it's parents
 */
function routeToName(route) {
    let names = route.substring(1).split("/")
    for (let i = 0; i < names.length; i++) { 
        names[i] = capitalizeFirstLetter(names[i])       
        do {
            let index = names[i].indexOf('-')
            if (index === -1) {
                break
            }
            names[i] = names[i].substring(0, index) + ' ' + names[i].substring(index + 1, index + 2).toUpperCase() + names[i].substring(index + 2)
            names[i] = names[i].replace('-', ' ')
        } while (false)    
    }
    return names
}

/**
 * Takes in a value in seconds and if it reaches 60 seconds 
 * it will be converted to minutes, if it reaches an hour 
 * it will be converted to hours, if it reaches a day it 
 * will be converted to days 
 * @param {number} seconds Seconds to convert 
 * @returns Minutes, Hours, or Days as a string
 */
function convertSeconds(seconds) {
    if (seconds === 0) {
        return 'Ongoing'
    }
    // Else If milliseconds are longer then a day
    else if (seconds >= 60 * 60 * 24) {
        let days = Math.floor(seconds / (60 * 60 * 24))
        if (days > 1) {
            return (`${days} days`)
        }
        else {
            return (`${days} day`)
        }
    }
    // If milliseconds are longer than an hour
    else if (seconds >= 60 * 60) {
        return (`${Math.floor(seconds / (60 * 60))}h`)
    }
    // If milliseconds are longer than a minute
    else if (seconds >= 60) {
        return (`${Math.floor(seconds / (60))}m`)
    }
    // If milliseconds are longer than a second
    else if (seconds < 60) {
        return (`${Math.floor(seconds)}s`)
    }
    else {
        return '0s'
    }
}

/**
 * Takes in a function a returns true if the function is asynchronous and false if otherwise
 * @param {function} fn  Function to chekc if async
 * @returns Boolean of whether not the function is async
 */
function isAsync(fn) {
    return fn.constructor.name === 'AsyncFunction'
}

/**
 * Pass through a time range string and return the start date and end date of the time range
 * @param {string} timeRange Time range string 
 * @param {number} customStart Custom start if any
 * @param {number} customEnd Custom end if any
 * @param {object} defaults Default for timerange if none selected
 * @param {number} defaults.startTime Start time of default date range
 * @param {number} defaults.endTime End time of default date range
 * @returns An array if successful finding time range
 */
function getDatesFromTimeRange(timeRange, customStart, customEnd, {startTime = null, endTime = null} = {}) {
    let startDate
    let endDate
    if (timeRange === 'LastHour') {
        startDate = new Date()
        endDate = new Date(new Date().setSeconds(59, 999))
        startDate.setHours(startDate.getHours() - 1, 0, 0, 0)
    }
    else if (timeRange === 'Last4Hours') {
        startDate = new Date()
        endDate = new Date(new Date().setSeconds(59, 999))
        startDate.setHours(startDate.getHours() - 4, 0, 0, 0)
    }
    else if (timeRange === 'Last12Hours') {
        startDate = new Date()
        endDate = new Date(new Date().setSeconds(59, 999))
        startDate.setHours(startDate.getHours() - 12, 0, 0, 0)
    }
    else if (timeRange === 'Today') {
        startDate = new Date(new Date().setHours(0, 0, 0, 0))
        endDate = new Date(new Date().setHours(23, 59, 59, 999))
    }
    else if (timeRange === 'Last24Hours') {
        startDate = new Date()
        startDate.setHours(startDate.getHours() - 24, 0, 0, 0)
        endDate = new Date(new Date().setSeconds(59, 999))
    }
    else if (timeRange === 'Yesterday') {
        startDate = new Date()
        endDate = new Date()
        startDate.setDate(startDate.getDate() - 1)
        endDate.setDate(endDate.getDate() - 1)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
    }
    else if (timeRange === '2DaysAgo') {
        startDate = new Date()
        endDate = new Date(new Date().setHours(23, 59, 59, 999))
        startDate.setDate(startDate.getDate() - 2)
        endDate.setDate(endDate.getDate() - 2)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
    }
    else if (timeRange === 'Last7Days') {
        startDate = new Date()
        endDate = new Date(new Date().setMinutes(59, 59, 999))
        startDate.setDate(startDate.getDate() - 6) // subtract 6 full days to get 7th day
        startDate.setHours(0, 0, 0, 0)
    }
    else if (timeRange === 'ThisMonth') {
        startDate = new Date()
        endDate = new Date()
        startDate.setDate(1)
        endDate.setDate(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate())
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
    }
    else if (timeRange === 'ThisWeek') {
        // Get end date (current date)
        endDate = new Date()
        let dayofweek = endDate.getDay()
        
        // Get start date from ms
        startDate = new Date(endDate.getTime())
        
        // Get todays date
        let todaysDate = startDate.getDate()
        
        // Subtract day of week from todays date
        startDate.setDate(todaysDate - dayofweek)

        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
    }
    else if (timeRange === 'Last30Days') {
        startDate = new Date()
        endDate = new Date(new Date().setMinutes(59, 59, 999))
        startDate.setDate(startDate.getDate() - 29) // subtract 29 full days to get 30th day
        startDate.setHours(0, 0, 0, 0)
    }
    else if (timeRange === 'Last28Days') {
        startDate = new Date()
        endDate = new Date(new Date().setMinutes(59, 59, 999))
        startDate.setDate(startDate.getDate() - 27) // subtract 27 full days to get 28th day
        startDate.setHours(0, 0, 0, 0)
    }
    else if (timeRange === 'ThisYear') {
        startDate = new Date()
        endDate = new Date()
        startDate.setDate(1)
        startDate.setMonth(0)
        endDate.setMonth(12)
        endDate.setDate(-1)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
    }
    else if (timeRange === 'AllTime') {
        let res = [undefined, undefined]
        if (startTime !== null) {
            res[0] = startTime
        }

        if (endTime !== null) {
            res[1] = endTime 
        }

        return res
    }
    else if (timeRange === 'Custom') {
        // Get custom start date
        startDate = new Date(customStart)
        
        // Get custom end date
        endDate = new Date(customEnd)
        
        // If there are no specific hours in timestamps, set to start and end of the day
        if (startDate.getHours() === 0 && endDate.getHours() === 0 && endDate.getMinutes() === 0) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 59, 999)
        }
    }
    else {
        return [undefined, undefined]
    }

    return [startDate.getTime(), endDate.getTime()]
}

/**
 * Get start and end time range based on year, month, day, and hours. Placing only a year will give you a year
 * @param {number} years What year to get a all time range for
 * @param {number} month What month in the year to get a 1 month range for
 * @param {number} days  What days in the month to get a 1 day range for
 * @param {number} hours What hours in the day to get 1 hour range for range for
 */
function getDateFromStartToDuration(years = null, months = null, days = null, hours = null) {
    let args = []
    
    if (years !== null) {
        args.push(years)
    }
    
    if (months !== null) {
    		args.push(months)
    } 

    if (days !== null) {
        args.push(days)
    }

    if (hours !== null) {
        args.push(hours)
    }
    
    let startArgs = [...args]
    let endArgs = [...args]
    
    endArgs[endArgs.length - 1] += 1
   	
    while(endArgs.length < 7) {
    	if (endArgs.length === 6 ) {
      	    endArgs.push(-1)
        }
        else if (endArgs.length === 2) {
      	    endArgs.push(1)
        }
        else {
    		endArgs.push(0)
    	}
    }
    
    if (startArgs.length === 1) {
    	startArgs.push(0)
    }
    
    return [new Date(...startArgs), new Date(...endArgs)]
}


/**
 * Show changed icon
 * @param {boolean} changed Has a field change
 * @returns If changed is true return changed icon 
 */
function showChangedIcon (changed) {
    return ((changed) ? <i className="fas fa-circle changedIcon" /> : "")
}

/**
 * Show changed color
 * @param {boolean} changed Has a field change
 * @returns If changed is true return changed class 
 */
function showChangeColorClass (changed) {
    return ((changed) ? " changed" : "")
}

/**
 * Show error icon
 * @param {boolean} hasError Field has error
 * @returns If hasError is true return error icon 
 */
function showErrorIcon (hasError) {
    return ((hasError) ? <i className="fas fa-times errorIcon" /> : "")
}

/**
 * @description Get dataview features
 * @param {object} features User available uiFeatures
 * @param {string[]} allowed Allowed features
 * @param {object} options Addional dataview feature options
 * @param {boolean} options.useLocation True use default specifed locations, false don't use any specified locations
 * @param {object[]} options.additionalFeatures Additional custom feature options, if useLocation is not set to false, location must be menu or or viewBar for each custom feature 
 * @returns {object} Object of dataview features on page wit obj.name
 */
function getShowFromFeatures(features = [], allowed = [], {useLocation = true, additionalFeatures = []} = {}) {
    /**
     * Pseudo Code
     *  Init data
     *  If useLocation is true
     *      Init data with location in place
     *  Else
     *      Init data withour locations
     *  If features is not undefined
     *      If add is an allowed feature
     *          Get add feature
     *          Check if add feature is defined
     *          If use location
     *              Push to data viewbar
     *          Else
     *              Push to data
     *      If edit is an allowed feature
     *          Get edit feature
     *          Check if edit feature is defined
     *          If use location
     *              Push to data menu
     *          Else
     *              Push to data
     *      If delete is an allowed feature
     *          Get edit feature
     *          Check if edit feature is defined
     *          If use location
     *              Push to data menu
     *          Else
     *              Push to data
     *      If custom is an allowed feature
     *          Get custom feature
     *          Check if custom feature is defined
     *          If use location
     *              Push to data menu
     *          Else
     *              Push to data
     *  If useLocation is true
     *      Separate custom features by location
     *      Populate custom features
     *  else
     *      Populate custom features
     *      
     */
    
    let data

    if (useLocation) {
        // Init data
        data = {
            // View Bar Location
            viewBar: [
                
            ],
            // Menu Location
            menu: [
                
            ]
        }
    }
    else {
        // Init data
        data = [
        ]
    }

    // If features available
    if (features) {
        // Ui feature permit add
        if (allowed.includes('add')) {
            // Get add feature
            let addFeat = features.find((feature) => feature.name === 'add')

            // Check for add feature
            if (addFeat) {
                // Use default location
                if (useLocation) {
                    data.viewBar.push({ ...addFeat, options: {icon: "fas fa-add"}})
                }
                else {
                    data.push(addFeat)
                }
            }
        }

        // Ui feature permit edit
        if (allowed.includes('edit')) {
            // Get edit feature
            let editFeat = features.find((feature) => feature.name === 'edit')

            // Check for edit feature
            if (editFeat) {
                // Use default location
                if (useLocation) {
                    data.menu.push(editFeat)
                }
                else {
                    data.push(editFeat)
                }
            }
        }

        // Ui feature permit delete
        if (allowed.includes('delete')) {
            // Get delete feature
            let deleteFeat = features.find((feature) => feature.name === 'delete')

            // Check for delete feature
            if (deleteFeat) {
                // Use default location
                if (useLocation) {
                    data.menu.push(deleteFeat)
                }
                else {
                    data.push(deleteFeat)
                }
            }
        }

        // Ui feature permit custom
        if (allowed.includes('custom')) {
            // Get custom features
            let customFeatures = features.filter((feature) => feature.name === 'custom')

            // Check for custom features
            if (customFeatures) {
                customFeatures.map(feat => {
                    if (useLocation) {
                        data.menu.push(feat)
                    }
                    else {
                        data.push(feat)
                    }
                })
            }
        }
    }

    // If useLocation true
    if (useLocation) {
        // Separate custom features by location
        let customMenuFeatures = additionalFeatures.filter(feature => feature.location === 'menu' && (feature.requires === undefined || (feature.requires !== undefined && ApiManager.session.hasUiFeatures(feature.requires))))
        let customViewBarFeatures = additionalFeatures.filter(feature => feature.location === 'viewBar' && (feature.requires === undefined || (feature.requires !== undefined && ApiManager.session.hasUiFeatures(feature.requires))))
        // Populate custom features
        data.viewBar.push(...customViewBarFeatures)
        
        // Populate custom features
        data.menu.push(...customMenuFeatures)
    }
    else {
        data.push(...additionalFeatures)
    }

    return data
}

/**
 * @description Get search params from url and conver to an object
 * @param {string} searchParams Search params to parse if not null, If null parse what is in the url
 * @param {boolean} decoder Whether or not to use decoder 
 * @returns search params in object format
 */
function decodeSearchParams(searchParams = null, decoder = null) {
    let search
    if (searchParams === null) {
        if (decoder === null) {
            search = qs.parse(window.location.search.replace("?", ""))
        }
        else {
            search = qs.parse(window.location.search.replace("?", ""), {decoder: (c) => c})
        }
    }
    else {
        if (decoder === null) {
            search = qs.parse(searchParams.replace("?", ""))
        }
        else {
            search = qs.parse(searchParams.replace("?", ""), {decoder: (c) => c})
        }
    }

    return (search)
}

/**
 * Encode object to url search query
 * @param {object} searchParamsObject Search params object to conver to string 
 * @returns Url search query string
 */
function encodeSearchParams(searchParamsObject) {
    return qs.stringify(searchParamsObject)
}

/**
 * @description Get parents from route
 * @param {string} route Page route
 */
function getParents(route) {
    /**
     * Pseudo Code
     *  Split url by /
     *  Shift the array (Removes empty string from the array)
     *  Pop the last item (removes the child from the array)
     *  Return parents
     */
    let parents = route.split('/')
    parents.shift()
    parents.pop()
    return parents
}

/**
 * 
 * @param {string} currentPath Current pathname 
 * @param {object} history React router dom history
 */
function handleTimeout(currentPath, history) {
    if (!currentPath.startsWith('/timeout')) {
        // Go to timeout page
        history.push({pathname: '/timeout', state: history.location})
    }
}

/**
 * Get alert type icon class name
 * @param {string} type Alert type 
 * @returns {string} Alert type icon as string
 */
function getAlertTypeIcon(type) {
    switch (type) {
    case 'General':
        return 'fas fa-book'
    case 'Vape':
        return 'fas fa-smoking'
    case 'Sound': 
        return 'fas fa-volume-up'
    case 'Aggression': 
        return 'fa-solid fa-person-harassing'
    case 'Noise': 
        return 'fa-solid fa-waveform'
    case 'Temperature':
        return 'fas fa-thermometer-three-quarters'
    case 'Tamper':
        return 'fa-solid fa-hammer-crash'
    case 'Rh':
    case 'Humidity':
        return 'fas fa-tint'
    case 'Light':
        return 'fas fa-lightbulb'
    case 'Motion':
        return 'fas fa-walking'
    case 'CO':
        return 'fa-solid fa-smoke'
    case 'ECO2':
        return 'fa-solid fa-smoke'
    case 'NH3':
        return 'fa-solid fa-smoke'
    case 'NO2':
        return 'fa-solid fa-smoke'
    case 'Gas':
        return 'fas fa-burn'
    case 'Iaq':
        return 'fas fa-wind'
    case 'Particulate':
        return 'fas fa-lungs'
    case 'Tvoc':
        return 'fa-solid fa-flask-vial'
    case 'Pdf-Monthly':
        return 'fa-solid fa-newspaper'
    case 'Pdf-Weekly':
        return 'fa-solid fa-memo'
    case 'Heatmap-Alerts':
        return 'fa-sharp fa-solid fa-grid-4'
    default:
        return ''
    }
}

/**
 * Get ratings type icon class name
 * @param {string} type Ratings type 
 * @returns {string} Ratings type icon as string
 */
function getRatingTypeIcon(type) {
    /**
     * Pseudo code
     *  Switch for type
     *      Case Return icon where type is equal to value
     */
    switch (type) {
    case 'AirQuality':
        return 'fas fa-wind'
    case 'Comfort': 
        return 'fas fa-hand-holding-heart'
    case 'Behavior': 
        return 'fa-solid fa-head-side-brain'
    default:
        return ''
    }
}

/**
 * Get weather icon class name
 * @param {string} value Weather description
 * @returns {string} Weather icon as string
 */
function getWeatherIcon(value) {
    switch (value) {
    case 'clear sky day':
        return 'fas fa-sun'
    case 'clear sky night':
        return 'fas fa-moon'
    case 'few clouds day':
        return 'fas fa-cloud-sun'
    case 'few clouds night':
        return 'fas fa-cloud-moon'
    case 'cloudy':
        return 'fas fa-cloud'
    case 'showers day':
        return 'fas fa-cloud-sun-rain'
    case 'showers night':
        return 'fas fa-cloud-moon-rain'
    case 'rain':
        return 'fas fa-cloud-rain'
    case 'thunderstorm':
        return 'fas fa-bolt'
    case 'snow':
        return 'fas fa-snowflake'
    case 'fog': 
        return 'fas fa-smog'
    default: 
        return ''
    }
}

/**
 * Get device status text, status icon, and status class name
 * @param {boolean} isOnline Is device online 
 * @param {boolean} hasErrors Does device have errors
 * @param {boolean} isReset The device has gone through factory reset
 * @param {boolean} explicit If true and device has error show whether the device is offline or online default to false
 * @returns {object} Object containing statusText, statusIcon, & statusClassName
 */
function getDeviceStatus(isOnline, hasErrors, isReset, explicit = false) {
    let statusText, statusIcon, statusClassName

    if (isReset === true) {
        statusText = "RESET"
        statusIcon = 'fas fa-sync'
        statusClassName = 'statusReset'
    }
    else if (hasErrors === true) {
        statusText = "ERROR"

        if (explicit === true) {
            statusText += isOnline === true ? ' ONLINE' : ' OFFLINE'
        }

        statusIcon = 'fas fa-exclamation'
        statusClassName = 'statusError'
    }
    else if (isOnline === true) {
        statusText = "ONLINE"
        statusIcon = 'fas fa-check'
        statusClassName = 'statusTrue'
    }
    else {
        statusText = "OFFLINE"
        statusIcon = 'fas fa-times'
        statusClassName = 'statusFalse'
    }

    return {
        statusText,
        statusIcon,
        statusClassName
    }
}

/**
 * Get device ip text
 * @param {string} ip Devices reported ip
 * @param {boolean} isOnline Is device online 
 * @param {int} dateCreated Date device was added to site as ms time stamp 
 * @param {int} connectionChangedAt Last time connection status has changed as ms time stamp
 * @returns {String} Ip to display
 */
function getDeviceIp(ip, isOnline, dateCreated, connectionChangedAt) {
    if (isOnline) {
        return ip
    }
    else if (isOnline === false && dateCreated < connectionChangedAt) {
        return ip
    }
    else {
        return ""
    }
}

/**
 * Convert Date object to utc ms
 * @param {object} date object of date values
 * @param {number} object.year year
 * @param {number} object.month month
 * @param {number} object.date date 
 * @returns {number} Date in utc ms
 */
function getUtcMsFromDateObj(dateObj) {
    /**
     * Pseudo Code
     *  If date is a number return
     *  If year but month and day do not exist
     *      return date with only year
     *  If year and month but day does not exist
     *      return date with year and month
     *  If year and month and day do not exist
     *      return null
     *  Else
     *      return date with year month and day
     */
    if (Number.isInteger(dateObj)) {
        return dateObj
    }
    
    if (dateObj.Year !== undefined && dateObj.Month === undefined && dateObj.Day === undefined) {
        return new Date(dateObj.Year).getTime()
    }
    else if (dateObj.Year !== undefined && dateObj.Month !== undefined && dateObj.Day === undefined) {
        return new Date(dateObj.Year, dateObj.Month).getTime()
    }
    else if (dateObj.Year === undefined && dateObj.Month === undefined && dateObj.Day === undefined) {
        return null
    }
    else {
        return new Date(dateObj.Year, dateObj.Month, dateObj.Day).getTime()
    }
}

/**
 * Convert utc ms to date object
 * @param {number} date date in utc ms
 * @returns {object} date object
 */
function getDateObjFromMs(date) {
    /**
     * Pseudo Code
     *  If date is an object return
     *  return date object
     */
    if (date instanceof Object) {
        return date
    }

    return {
        Year: new Date(date).getFullYear(),
        Month: new Date(date).getMonth(),
        Day: new Date(date).getDate()
    }
}

/**
 * Convert time object to 24 hour value
 * @param {Object} timeObject object containing time values
 * @param {number} timeObject.Hour Hour int
 * @param {number} timeObject.Minute Minute int
 * @param {string} timeObject.Meridiem AM/PM string
 * @returns {string} time value
 */
function createScheduleTime({Hour, Minute, Meridiem = undefined}) {
    /**
     * Pseudo Code
     *  calculate minute
     *  get hour
     *  If 12 AM
     *      subtract 12
     *  If PM
     *      If hour is between 1 and 11
     *          add 12
     *  add hour and minute to get time value
     *  return time as string
     */
    let minute = Minute / 60
    let hour = Hour


    if (Meridiem === 'AM' && hour === 12) {
        hour -= 12
    }
    if (Meridiem === 'PM') {
        if (hour >= 1 && hour < 12) {
            hour += 12
        }
    }
    
    
    let time = hour + minute
    return `${time}`
}

/**
 * Convert 24hr database time value to time object
 * @param {string} dbTime time value
 * @returns {Object} time object
 */
function dbTimeToRealTime(dbTime) {
    /**
     * Peeudo Code
     *  convert time to float
     *  get hour
     *  convert minute from 0.0-0.99 value to 0-60 value
     *  meridiem default to AM
     *  If hour is greater or equlal to 12
     *      set meridiem to PM
     *  If browser is 12hour setting
     *      convert hour to 12 hour format
     *  return time object
     */
    let timeValue = parseFloat(dbTime)
    let hour = Math.floor(timeValue)
    let minute = Math.floor((timeValue % 1) * 60)
    let meridiem = 'AM'
    
    if (hour === 24) {
    	meridiem = 'AM'
    }
    else if (hour >= 12) {
        meridiem = 'PM'
    }
    
    if (is12Hour()) {
        // convert 24hr to 12hr
        hour = ((hour + 11) % 12 + 1)
    }
    else {
        // Convert 24hr to 24hr
        hour = ((hour) % 24)
    }

    return {
        Hour: hour,
        Minute: minute,
        ...(is12Hour() && {Meridiem: meridiem}),
        toString: () => {
            if (is12Hour()) {
                return `${hour}:${minute.toString().padStart(2, "0")}${meridiem}`
            }
            else {
                return `${hour}:${minute.toString().padStart(2, "0")}`
            }
        }
    }
}

/**
 * Get hour options for schedule
 * @param {object} increment Hour increments, defaults to 1
 * @returns {object[]} Our options for schedule header
 */
const getHourOptions = (increment = null) => {
    /**
     * Pseudo Code
     *  initialize times array
     *  if 12 hour
     *      generate array of hour objects for 12 hours
     *  else if 24 hour
     *      generate array of hour objects for 24 hours
     *  return times
     */
    let times = []
    for (let i = 0; i < 24; i++) {
        let startTime = dbTimeToRealTime(i).toString()
        let endTime = dbTimeToRealTime(i + ((increment ?? 1) - 1)).toString().replace(':00', ':59')

        let value = parseInt(startTime.split(':')[0]) 
        
        times.push({key: value, value: value, text: startTime.replace(":00", ""), range: `${startTime} - ${endTime}`})
    }

    if (increment !== null) {
        times = times.filter((time, index) => index % increment === 0)
    }
    
    return times
}

/**
 * Check if url string is a valid url
 * @param {string} str Url string 
 * @returns {boolean} True if string is a valid url
 */
function validURL(str) {
    /**
     * Pseudo Code
     *  Check Pattern
     */

    const pattern = new RegExp('^(http(s)?:\\/\\/)?' + // Protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and Path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query String
      '(\\#[-a-z\\d_]*)?$', 'i') // Fragment locator
    return !!pattern.test(str)
}

/**
 * Advance enumerate cameras on a device
 * @returns Devices available camers
 */
function advanceEnumerateDevices() {
    return new Promise((resolve, reject) => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            let devices = {}
            mediaDevices.forEach(mediaDevice => {
                if (mediaDevice.kind === 'videoinput') {
                    devices[mediaDevice.label] = mediaDevice.deviceId
                }
            })
            resolve({devices: devices})
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * Advanced navigation permission query, works with Firefox
 * @param {object} permissionDesc Permision description 
 * @returns Async Function
 */
function advancedNavigationPermissionsQuery(permissionDesc) {
    /**
     * Psuedo Code
     *  Return results of navigator permission query
     */

    /**
     * Handle Safari
     */
    if (navigator?.permissions?.query === undefined) {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({ video: permissionDesc.constraints})
                .then((stream) => {
                    let track = stream.getVideoTracks()[0]
                    track.applyConstraints({ focusMode: 'continuous' })
                    resolve({ name: 'camera', state: 'granted', stream })
                })
                .catch((err) => {
                    if (err.name === 'PermissionDeniedError') {
                        resolve({ name: 'camera', state: 'denied' })
                    }
                    else if (err.name === 'NotFoundError') {
                        reject({message: 'noDevices'})
                    }
                    else {
                        reject(err)
                    }
                })
        })
    }
    /**
     * Handle Chrome
     */
    else {
        return new Promise((resolve, reject) => {
            navigator.permissions.query(permissionDesc)
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'granted') {
                        resolve({ name: 'camera', state: 'granted'})
                    }
                    else {
                        navigator.mediaDevices.getUserMedia({ video: permissionDesc.constraints})
                            .then((stream) => {
                                let track = stream.getVideoTracks()[0]
                                track.applyConstraints({ focusMode: 'continuous' })
                                resolve({ name: 'camera', state: 'granted', stream })
                            })
                            .catch((err) => {
                                if (err.name === 'PermissionDeniedError') {
                                    resolve({ name, state: 'denied' })
                                }
                                else if (err.name === 'NotFoundError') {
                                    reject({message: 'noDevices'})
                                }
                                else {
                                    reject(err)
                                }
                            })
                    }
                })
                .catch((e) => {    
                    /**
                     * Handle Mozilla
                     */
                    if (permissionDesc.name === 'camera') {
                        const { constraints, peerIdentity } = permissionDesc
                        navigator.mediaDevices.getUserMedia({ video: constraints || true, peerIdentity })
                            .then((stream) => {
                                let track = stream.getVideoTracks()[0]
                                track.applyConstraints({ focusMode: 'continuous' })
                                resolve({ name: 'camera', state: 'granted', stream })
                            })
                            .catch((err) => {
                                if (err.name === 'PermissionDeniedError') {
                                    resolve({ name: 'camera', state: 'denied' })
                                }
                                else if (err.name === 'NotFoundError') {
                                    reject({message: 'noDevices'})
                                }
                                else {
                                    reject(err)
                                }
                            })                 
                    }
                    else {
                        reject(e)
                    }
                })
        })
    }
}

/**
 * Closes stream
 * @param {object} stream Video stream 
 */
function closeStream(stream) {
    stream.getTracks().forEach(function(track) {
        track.stop()
    })
} 

/**
 * Check if interface is on
 * @param {object[]} interfaceFields 
 * @param {object} interfaceSettings 
 * @returns {boolean} True if interface is on false if otherwise
 */
const isInterfaceOn = (interfaceFields, interfaceSettings) => {
    /**
     * Pseudo Code
     *  Init interface is on to true
     *      If fields to turn off is not undefined
     *      Filter fields if the field is a field to turn off
     *      For some filtered field
     *          If type is range
     *              If field lo is equal to min and field hi is equal to max
     *                  return false
     *              else
     *                  return true
     *          If type is slider
     *              If field value is at max
     *                  return false
     *              else
     *                  return true
     *          If type is select
     *              If select field value is set to off
     *                  return false
     *              else return true
     *          else
     *              return true
     *      else 
     *          return null
     *      return interaface is on
     */
    
    let interfaceIsOn = true
    
    interfaceIsOn = interfaceFields.some(field => {
        if (field.FieldType === 'range') {
            if (field.Ui.AbsMin === interfaceSettings[field.Key][0] && field.Ui.AbsMax === interfaceSettings[field.Key][1]) {
                return false
            }
            else {
                return true
            }
        }
        else if (field.FieldType === 'slider') {
            if (field.Ui.OffMode === 'min') {
                if (field.Ui.AbsMin === interfaceSettings[field.Key]) {
                    return false
                }
                else {
                    return true
                }
            }
            else if (field.Ui.OffMode === 'max') {
                if (field.Ui.AbsMax === interfaceSettings[field.Key]) {
                    return false
                }
                else {
                    return true
                }
            }
            else {
                return true
            }
        }
        else if (field.FieldType === 'select') {
            let offOption = field.Ui.Options.find(option => option.IsOffOption === true)
            
            if (offOption === undefined) {
                return false
            }
            else if (interfaceSettings[field.Key] === offOption.Key) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    })

    return interfaceIsOn
}

/**
 * Check object builder value for errors
 * @param {string} name Object builder name
 * @param {string} displayName Error display nam
 * @param {object} values Object builder value
 * @param {object} location Location of error
 * @param {number} location.index Index of where the error is located
 * @param {number[]} location.parents Parental path of error
 * @returns object if there is an error, otherwise undefined
 */
const checkObjectBuilderValueForErrors = (name, displayName, values, {level = 0, parents = []} = {}) => {
    /**
     * Pseudo Code
     *  Init error
     *  Loop over value for error check
     *      If no key specified for item
     *          populate error
     *      Else if no param type selected
     *          populate error
     *      Else if param type is specified
     *          If no type is selected
     *              populate error
     *          Else if type is object
     *              recursively check object for errors
     *          Else if value is not of type object and value is undefined or an empty string
     *              populate error
     */

    let error

    // Loop over value for error check
    values.every((value, index) => {
        // If no key specified for item
        if (value.key === undefined || value.key === '') {
            // Populate error
            error = {
                msg: `${displayName} Param Missing A Key`,
                name: name,
                tab: 'WEBHOOK REQUEST',
                details: {
                    key: 'key',
                    level: level,
                    parents: parents,
                    index: index,
                    pathToItem: [...parents, index],
                    pathToValue: [...parents, index, 'key'],
                    msg: {
                        key: 'Please enter a key'
                    }
                }
            }

            return false
        }
        // If no param type selected
        else if (value.paramType === '' || value.paramType === undefined) {
            // Populate error
            error = {
                msg: `${displayName} Param Missing Param Type: ${value.key}`,
                name: name,
                tab: 'WEBHOOK REQUEST',
                details: {
                    key: 'paramType',
                    level: level,
                    parents: parents,
                    index: index,
                    pathToItem: [...parents, index],
                    pathToValue: [...parents, index, 'paramType'],
                    msg: {
                        paramType: 'Please select a paramType'
                    }
                }
            }

            return false
        }
        else if (value.paramType === 'config') {
            if (value.type === '' || value.type === undefined) {
                error = {
                    msg: `${displayName} Param Missing Type: ${value.key}`,
                    name: name,
                    tab: 'WEBHOOK REQUEST',
                    details: {
                        key: 'type',
                        level: level,
                        parents: parents,
                        index: index,
                        pathToItem: [...parents, index],
                        pathToValue: [...parents, index, 'type'],
                        msg: {
                            type: 'Please select a type'
                        }
                    }
                }

                return false
            }
        }
        // Error check specified param types
        else if (value.paramType === 'specified') {
            // Error check if a type is not specified
            if (value.type === undefined || value.type === '') {
                error = {
                    msg: `${displayName} Param Missing Type: ${value.key}`,
                    name: name,
                    tab: 'WEBHOOK REQUEST',
                    details: {
                        key: 'type',
                        level: level,
                        parents: parents,
                        index: index,
                        pathToItem: [...parents, index],
                        pathToValue: [...parents, index, 'type'],
                        msg: {
                            type: 'Please select a type'
                        }
                    }
                }

                return false
            }
            else if (value.type === 'object') {
                error = checkObjectBuilderValueForErrors(name, displayName, value?.children ?? [], {level: level + 1, parents: [...parents, index, 'children']})
                return false
            }
            else if (value.type === 'array') {
                error = checkObjectBuilderValueForErrors(name, displayName, value?.items ?? [], {level: level + 1, parents: [...parents, index, 'items']})
                return false
            }
            else if (value.type === 'authorization') {
                error = checkObjectBuilderValueForErrors(name, displayName, value?.auth ?? [], {level: level + 1, parents: [...parents, index, 'auth']})
                return false
            }
            // Error check non object params with no value
            else if (value.type !== 'object' && (value.value === undefined || value.value === '')) {
                error = {
                    msg: `${displayName} Param Missing Value: ${value.key}`,
                    name: name,
                    tab: 'WEBHOOK REQUEST',
                    details: {
                        key: 'value',
                        level: level,
                        parents: parents,
                        index: index,
                        pathToItem: [...parents, index],
                        pathToValue: [...parents, index, 'value'],
                        msg: {
                            value: 'Please enter a value'
                        }
                    }
                }

                return false
            }
        }

        return true
    })

    return error
}

/**
 * Check object builder value for errors
 * @param {string} name Webhook parameter name
 * @param {object} params Webhook event params
 * @param {object} webhookInterface Webhook parameter interface
 * @returns object if there is an error, otherwise undefined
 */
const checkWebhookParamValuesForErrors = (name, params = {}, webhookInterface = {}) => {
    /**
     * Pseudo Code
     *  Get webhook interface keys
     *  Init error
     *  Are there any header params to check ?
     *      For every interface key
     *          Get the type of webhook interface
     *          Get interfave param value
     *          If interface param is of type boolean
     *              If interface param is not equal to "true" or "false"
     *                  populate error
     *          If interface param is of type string
     *              If interface param is undefined or an empty string
     *                  populate error
     *          If interface param is of type number
     *              If interface param is not a number
     *                  populate error
     */

    // Get webhook interface keys
    let interfaceKeys = Object.keys(webhookInterface)
    let error

    // Are there any header params to check ?
    if (interfaceKeys.length > 0 ) {
        // For every interface key
        interfaceKeys.every((key) => {
            // Get the type of webhook interface
            const { type, arrayType } = webhookInterface[key]

            // Get interface param value
            const paramValue = params[key]
            
            // If interface param is of type boolean
            if (type === 'boolean') {
                // If interface param not equal to true or false
                if (paramValue !== 'true' && paramValue !== 'false') {
                    error = {
                        msg: `${capitalizeFirstLetter(name)} Missing Value: ${key}`,
                        name: name,
                        tab: 'PARAMS',
                        details: {
                            group: {
                                [key]: 'Please select true or false'
                            }
                        }
                    }

                    return false
                }
            }

            // If interface param is of type string
            else if (type === 'string') {
                // If interface param is undefined or an empty string
                if ((!paramValue) || (paramValue && paramValue === '')) {
                    error = {
                        msg: `${capitalizeFirstLetter(name)} Missing Value: ${key}`,
                        name: name,
                        tab: 'PARAMS',
                        details: {
                            group: {
                                [key]: 'Please enter a value'
                            }
                        }
                    }
                    return false
                }
            }

            // If interface param is of type number
            else if (type === 'number') {
                // If interface param is not a number
                if (isNaN(paramValue) || paramValue === '') {
                    error = {
                        msg: `${capitalizeFirstLetter(name)} Missing Value: ${key}`,
                        name: name,
                        tab: 'PARAMS',
                        details: {
                            group: {
                                [key]: 'Please enter a numeric value',
                            }
                        }
                    }
                    return false
                }
            }
            
            else if (type === 'array' && (paramValue ?? []).length > 0) {
                if (arrayType === 'number') {
                    for (let i = 0; i < paramValue.length; i++) {
                        // If interface param is undefined or an empty string
                        if (isNaN(paramValue[i]) || paramValue[i] === '') {
                            error = {
                                msg: `${capitalizeFirstLetter(name)} Missing Value in Array ${key} at Index: ${i}`,
                                name: name,
                                tab: 'PARAMS',
                                details: {
                                    group: {
                                        [key]: {
                                            [i]: 'Please enter a numeric value',
                                        }
                                    }
                                }
                            }
                            return false
                        }
                    }
                    
                }
                else if (arrayType === 'string') {
                    for (let i = 0; i < paramValue.length; i++) {
                        // If interface param is undefined or an empty string
                        if ((!paramValue[i]) || (paramValue[i] && paramValue[i] === '')) {
                            error = {
                                msg: `${capitalizeFirstLetter(name)} Missing Value in Array ${key} at Index: ${i}`,
                                name: name,
                                tab: 'PARAMS',
                                details: {
                                    group: {
                                        [key]: {
                                            [i]: 'Please enter a value'
                                        }
                                    }
                                }
                            }
                            return false
                        }
                    }
                }
                else if (arrayType === 'boolean') {
                    for (let i = 0; i < paramValue.length; i++) {
                        // If interface param is undefined or an empty string
                        if (paramValue[i] !== 'true' && paramValue[i] !== 'false') {
                            error = {
                                msg: `${capitalizeFirstLetter(name)} Missing Value in Array ${key} at Index: ${i}`,
                                name: name,
                                tab: 'PARAMS',
                                details: {
                                    group: {
                                        [key]: {
                                            [i]: 'Please enter a value'
                                        }
                                    }
                                }
                            }
                            return false
                        }
                    }
                }
            }

            return true
        })
    }
    return error
}

/**
 * Take an array or an object and converts all Pascal Cases to Mixed Case
 * @param {array|object} obj Object or array to convert
 * @returns Converted object or array
 */
const toMixedCase = (obj) => {
    /** 
     * Psuedo Code 
     *  Init is Array function
     *  Init new object as an object or an array
     *  For every key in object
     *      Lower case the first letter
     *      If object at key is an object or is an array
     *          set newObj at key to the result of recursive function
     *      Else
     *          set newObj at key to value
    */

    const isArray = (val) => Array.isArray(val) 

    let newObj = isArray(obj) ? [] : {}

    for (let key in obj) {
        const newKey = key[0].toLowerCase() + key.slice(1)

        if (isObject(obj[key]) || isArray(obj[key])) {
            newObj[newKey] = toMixedCase(obj[key])
        } 
        else {
            newObj[newKey] = obj[key]
        }
    }

    return newObj
}

/**
 * Converts url string to an object containing
 * @param {string} urlString Url string 
 * @returns {{protocol: 'http'|'https', host: string, path: string, queryParams: string, hash: string, port: number toString: function}}  
 */
function parseUrl(urlString) {
    /**
     * Pseudo Code
     *  Create parsed url object
     *  Get url string
     *  If starts with http
     *      Create Url Regex
     *      Get matched groups for url regex
     *      Populate hash
     *      Populate host 
     *      Populate port
     *      Populate protocol
     *      Populate query params
     *      Populate path
     *  Else
     *      Split url params and path
     *      Populate path
     *      If params are available
     *          Split params into query params and hash
     *          Populate query params
     *          If hash is available
     *              Populate hash
     */

    // Create parsed url object
    const parsedUrl = Object.create({
        hash: undefined,
        host: undefined,
        port: undefined,
        protocol: undefined,
        queryParams: undefined,
        path: undefined,
        toString: function() {
            return `${this.protocol ? `${this.protocol}://` : ''}${this.host ?? ''}${this.port !== undefined ? `:${this.port}` : ''}${this.path ?? ''}${this.queryParams !== undefined ? `?${this.queryParams}` : ''}${this.hash !== undefined ? `#${this.hash}` : ''}`
        }
    })

    if (urlString.startsWith('http')) {
        // Create Url Regex
        const urlRegex = new RegExp(/^(?<protocol>https?\:)\/\/((?<host>[^:\/?#]*)(?:\:(?<port>[0-9]+))?)(?<path>[\/]{0,1}[^?#]*)(?<queryParams>\?[^#]*|)(?<hash>#.*|)$/)
    
        // Get matched groups for url regex
        let groupedMatches = (urlRegex.exec(urlString))?.groups ?? {}

        // Populate hash
        parsedUrl.hash = !groupedMatches.hash || groupedMatches.hash === '' ? undefined : groupedMatches.hash.slice(1)
        
        // Populate host
        parsedUrl.host = !groupedMatches.host || groupedMatches.host === '' ? undefined : groupedMatches.host
        
        // Populate port
        parsedUrl.port = !groupedMatches.port || groupedMatches.port === '' ? undefined : parseInt(groupedMatches.port)
       
        // Populate protocol
        parsedUrl.protocol = !groupedMatches.protcol || groupedMatches.protocol === '' ? undefined : groupedMatches.protocol.slice(0, -1)
        
        // Populate query params
        parsedUrl.queryParams = !groupedMatches.queryParams || groupedMatches.queryParams === '' ? undefined : groupedMatches.queryParams.slice(1)
        
        // Populate Path
        parsedUrl.path = !groupedMatches.path || groupedMatches.path === '' ? undefined : groupedMatches.path
    }
    else {
        // Split url into path and params
        let [path, params] = urlString.split('?')
        
        // Populate path
        parsedUrl.path = path

        // If params are available
        if (params) {
            // Split params into queryParams and hash
            let [queryParams, hash] = params.split('#')
        
            // Populate query params
            parsedUrl.queryParams = queryParams

            // If hash is available
            if (hash) {
                // Populate hash
                parsedUrl.hash = hash
            }
        }
    }

    return parsedUrl
}

/**
 * Move caret to textnode and position
 * @param {Node} textNode 
 * @param {number|"after"|"before"} position 
 */
function moveCaretToPositionInTextNode(textNode, position) {
    /**
     * Pseudo Code
     *  Get current selection
     *  Get current range
     *  If position is before
     *      Set start before
     *      Set end before
     *  If position is after
     *      Set start after
     *      Set end after
     *  Else
     *      Set start after new variable
     *      Set end after new variable
     *  Collapse range to one of its boundary points
     *  Removes all ranges from the selection node
     *  Adds selection to range
     */
    const selectObj = window.getSelection()
    
    const newRange = document.createRange()

    // If position is before
    if (position === 'before') {
        // Set start before
        newRange.setStartBefore(textNode)
        
        // Set end before
        newRange.setEndBefore(textNode)
    }
    // If position is after
    else if (position === 'after') {
        // Set start after
        newRange.setStartAfter(textNode)
        
        // Set end after
        newRange.setEndAfter(textNode)
    }
    else {
        // Set start after new variable
        newRange.setStart(textNode, position)
        
        // Set end after new variable
        newRange.setEnd(textNode, position)
    }

    // Collapses the Range to one of its
    // boundary points
    newRange.collapse(true)
    
    // Removes all ranges from the selection
    // except Anchor Node and Focus Node
    selectObj.removeAllRanges()

    // Adds a Range to a Selection
    selectObj.addRange(newRange)
}

/**
 * Query selector from node list
 * @param {keyof HTMLElementTagNameMap} selector 
 * @param {NodeList} elements 
 * @returns Filtered node list by selection
 */
function querySelectorFromNodeList(selector, elements) {
    return [].filter.call(elements, function(element) {
        /**
         * Pseudo Code
         *  If element doesnt match
         *      return false
         */

        if (!element.matches) {
            return false
        }

        return element.matches(selector)
    })
}

/**
 * Get index of node in list
 * @param {NodeList} nodeList 
 * @param {Node} node 
 * @returns {number} index of node
 */
function getIndexOfNode(nodeList, node) {
    return Array.prototype.indexOf.call(nodeList, node)
}

/**
 * Get webhook event error response
 * @param {number} statusCode Status code of webhook event response
 * @param {object} response Webhook event response
 * @param {"regular"|"single"} populate If populate regular populate iwth sentence fragment string, If single use single word text. Defaults to regular
 * @returns {string|import("react").ReactNode}
 */
function getWebhookEventErrorResponse(statusCode, response, populate = 'regular') {
    /**
     * Pseudo Code
     *  Init reason
     *  If status code is internal
     *      Try
     *          If status code is -1
     *              If request param or param config name is not populated throw
     *              If populate is regular
     *                  Set reason
     *              Else if populate is single
     *                  Set reason
     *          Else if status code is -2
     *              If populate is regular
     *                  Set reason
     *              Else if populate is single
     *                  Set reason
     *          Else if status code is -3
     *              If populate is regular
     *                  Set reason
     *              Else if populate is single
     *                  Set reason
     *          Else if status code is -4
     *              If populate is regular
     *                  Set reason
     *              Else if populate is single
     *                  Set reason
     */
    
    let reason

    // Status code is internal
    if (statusCode <= 0) {
        try {
            if (statusCode === -1) {
                if (!response.requestParam || !response.paramConfigName) {
                    if (populate === 'regular') {
                        throw "of an Unexpected Error"
                    }
                }
                if (populate === 'regular') {
                    reason = (
                        <>
                            <span>
                                {response.requestParam} 
                            </span>
                            <span>
                                {` has a Validation Error on `}
                            </span>
                            <span>
                                {response.paramConfigName}
                                .
                            </span>
                        </>
                    )
                }
                else if (populate === 'single') {
                    reason = 'Validation Error'
                }
            }
            else if (statusCode === -2) {
                if (populate === 'regular') {
                    reason = "Authentication Value Is Missing"
                }
                else if (populate === 'single') {
                    reason = 'Authentication Error'
                }
            }
            else if (statusCode === -3) {
                if (populate === 'regular') {
                    reason = "Webhoook Config was not found"
                }
                else if (populate === 'single') {
                    reason = 'Webhook Request was not found'
                }
            }
            else if (statusCode === -4) {
                if (populate === 'regular') {
                    reason = "of an Unexpected Error"
                }
                else if (populate === 'single') {
                    reason = 'Unexpected Error'
                }
            }
            else if (statusCode === 0) {
                if (populate === 'regular') {
                    reason = 'Error'
                }
                else {
                    reason = 'Error'
                }
            }
            else {
                if (populate === 'regular') {
                    reason = "of an Unexpected Error"
                }
                else if (populate === 'single') {
                    reason = 'Unexpected Error'
                }
            }
        }
        catch(e) {
            if (populate === 'regular') {
                reason = "of an Unexpected Error"  
            }
            else if (populate === 'single') {
                reason = 'Unexpected Error'
            }
        }
    }

    return reason
}

/**
 * Converts array to listed string
 * @param {string[]} arr Array of items to convert to string 
 * @example input: arr = ["vape", "sound", "tamper"], output: "vape, sound, & tamper"
 * @returns {string} Array shown as listed string
 */
function arrayToString(arr) {
    let str = arr.join(', ')

    if (arr.length === 2) {
        str = str.replace(',', ' &')
    }
    else if (arr.length > 2) {
        str = str.substring(0, str.lastIndexOf(", ")) + ', &' + str.substring(str.lastIndexOf(", ") + 1, str.length)
    }

    return str
}

/**
 * @typedef {{r: number, g: number , b: number}} rgbColor
 * @typedef {{h: number, s: number, l: number}} hslColor
 */

/**
 * Convert rgb color to hsl color
 * @param {rgbColor} rgbColor 
 * @returns {hslColor}
 */
function rgbToHsl({r, g, b}) {
    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // Achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        
        h /= 6;
    }

    return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
}

/**
 * Calculate rgb step
 * @param {number} ratio Ratio between 0 and 1
 * @param {rgbColor} startColor Starting color in sequence
 * @param {rgbColor} endColor Ending color insequence
 * @return {rgbColor}
 */
function calculateRGBStep(ratio, startColor, endColor) {
    let r = Math.round(startColor.r + ratio * (endColor.r - startColor.r));
    let g = Math.round(startColor.g + ratio * (endColor.g - startColor.g));
    let b = Math.round(startColor.b + ratio * (endColor.b - startColor.b));
    
    let color = ({
        r, 
        g, 
        b,
        toString: () => `rgb(${r},${g},${b})`
    }) 
    
    return color
}

/**
 * Calculate hsl step
 * @param {number} ratio Ratio between 0 and 1
 * @param {hslColor} startColor Starting color in sequence
 * @param {hslColor} endColor Ending color insequence
 * @return {hslColor}
 */
function calculateHSLStep(ratio, startColor, endColor) {
    let h = Math.round(startColor.h + ratio * (endColor.h - startColor.h));
    let s = Math.round(startColor.s + ratio * (endColor.s - startColor.s));
    let l = Math.round(startColor.l + ratio * (endColor.l - startColor.l));
    
    let color = ({
        h, 
        s, 
        l,
        toString: () => `hsl(${h},${s}%,${l}%)`
    })
    
    return color
}  

/** 
 * Generate multi step gradient
 * @param {Record<number, rgbColor>[]} rgbStops RGB color stops
 * @param {boolean} hslMode Enable hsl mode, defaults to false
 * @returns {rgbColor | hslColor}
 */
function generateMultiStepGradient(rgbStops, hslMode = false) {
    // Get sorted keys (step indices)
    const stepKeys = Object.keys(rgbStops).map(Number).sort((a, b) => a - b);

    // Init hsl stops
    let hslStops = {}

    // If in hsl mode
    if (hslMode === true) {
        // For each step get hsl
        for (let step in rgbStops) {
            hslStops[step] = rgbToHsl(rgbStops[step]);
        }
    }

    // Initialize gradient array
    let gradient = []

    // Initialize stops and calculate steps function
    let stops
    let calculateStep

    // If in hsl mode
    if (hslMode === true) {
        // Set stops to hsl stops
        stops = hslStops

        // Set calculate step function
        calculateStep = calculateHSLStep
    }
    else {
        // Set stops to rgb stops
        stops = rgbStops

        // Set calculate step function
        calculateStep = calculateRGBStep
    }

    // For each step key
    for (let i = 0; i < stepKeys.length - 1; i++) {
        // Start step
        let startStep = stepKeys[i];

        // End step
        let endStep = stepKeys[i + 1];

        // Starting color
        let startColor = stops[startStep];

        // Ending color
        let endColor = stops[endStep];
        
        // Start at 0 if startStep is at 0 otherwise add one
        for (let j = startStep !== 0 ? startStep + 1 : startStep ; j <= endStep; j++) {
            // Calculate ratio
            let ratio = (j - startStep) / (endStep - startStep);

            // Push to gradient
            gradient.push(calculateStep(ratio, startColor, endColor));
        }
    }

    return gradient.filter(color => color !== null);
}

export { 
    isObject, capitalizeFirstLetter, unCapitalizeFirstLetter, isBoolean, timeStampToReadable, timeStampToReadableTime, formatDate, is12Hour, routeToName, convertSeconds, isAsync, 
    getDatesFromTimeRange, showChangedIcon, showChangeColorClass, showErrorIcon, getShowFromFeatures, decodeSearchParams, encodeSearchParams, getParents, handleTimeout, getAlertTypeIcon, 
    getDeviceStatus, getDeviceIp, getWeatherIcon, createScheduleTime, dbTimeToRealTime, getRatingTypeIcon, validURL, advancedNavigationPermissionsQuery, closeStream, getHourOptions, advanceEnumerateDevices,
    isInterfaceOn, checkObjectBuilderValueForErrors, checkWebhookParamValuesForErrors, toMixedCase, parseUrl, isoTimeToUtcMs, getUtcMsFromDateObj, getDateObjFromMs, moveCaretToPositionInTextNode,
    querySelectorFromNodeList, getIndexOfNode, getWebhookEventErrorResponse, arrayToString, getDateFromStartToDuration, createFormatter, generateMultiStepGradient
}