/**
 * Class for handling users REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file users.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Users {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }
    
    /**
     * @description Gets user permissions
     * @returns {ResponseObject} Api response object
     */
    async getPermissions() {

        let config = {
            method: 'get',
            url: '/api/users/me/permissions',
        }

        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets users
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.authType Filter by authType
     * @param {string} searchFilters.forceUserTwoFactorAuth Filter by user's forced two factor auth
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.userRole Filter by user role
     * @param {boolean} searchFilters.isActive If true filter active users, if false filter inactive users
     * @param {boolean} searchFilters.textEnabled If true filter by if user has text enabled
     * @param {boolean} searchFilters.emailEnabled If true filter by if user has email enabled
     * @returns {ResponseObject} Api response object
     */
    async getUsers(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", authType = "All", forceUserTwoFactorAuth = 'All', userRole = [], listIds = [], isActive = null, textEnabled = null, emailEnabled = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search: encodeURIComponent(search)},
            ...(sortBy === "") ? {} : {sortBy},
            ...(authType === "All") ? {} : {authType},
            ...(forceUserTwoFactorAuth === "All") ? {} : {forceUserTwoFactorAuth},
            userRole,
            listId: listIds,
            ...(isActive !== null && {isActive: isActive}),
            ...(textEnabled !== null && {textEnabled: textEnabled}),
            ...(emailEnabled !== null && {emailEnabled: emailEnabled})
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/users${"?" + query}`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets users
     * @param {object} searchFilters
     * @param {string} searchFilters.siteId Site to get users for
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.authType Filter by authType
     * @param {string} searchFilters.forceUserTwoFactorAuth Filter by user's forced two factor auth
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.userRole Filter by user role
     * @param {boolean} searchFilters.countOnly If true only get count of users
     * @param {boolean} searchFilters.isActive If true filter active users, if false filter inactive users
     * @param {boolean} searchFilters.textEnabled If true filter by if user has text enabled
     * @param {boolean} searchFilters.emailEnabled If true filter by if user has email enabled
     * @returns {ResponseObject} Api response object
     */
    async getAllUsers({siteId = null, page = 0, order = "ASC", search = "", sortBy = "", authType = "All", forceUserTwoFactorAuth = "All", userRole = [], countOnly = false, isActive = null, textEnabled = null, emailEnabled = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search: encodeURIComponent(search)},
            ...(sortBy === "") ? {} : {sortBy},
            ...(authType === "All") ? {} : {authType},
            ...(forceUserTwoFactorAuth === "All") ? {} : {forceUserTwoFactorAuth},
            userRole,
            countOnly,
            ...(siteId !== null && {siteId: siteId}),
            ...(isActive !== null && {isActive: isActive}),
            ...(textEnabled !== null && {textEnabled: textEnabled}),
            ...(emailEnabled !== null && {emailEnabled: emailEnabled})
        }

        let query = this.session.getQuery({page, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/users/all${"?" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
    
    /**
     * @description Get my user's information
     * @returns {ResponseObject} Api response object
     */
    async getMe() {
        let config = {
            method: 'get',
            url: `/api/users/me?accountStatus=simple`,
        }
          
        let res = await this.axios(config)
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single user's information by id
     * @param {string} id User uuid  
     * @returns {ResponseObject} Api response object
     */
    async getUser({id}) {
        let config = {
            method: 'get',
            url: `/api/users/${id}?populate=simple&accountStatus=simple`,
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Deletes a specified user by id
     * @param {Object} params Function parameters
     * @param {string} params.id user id
     * @returns {ResponseObject} Api response object
     */
    async deleteUser({id}) {
        let config = {
            method: 'delete',
            url: `/api/users/${id}`
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })    
    }

    /**
     * @description Creates a specified user
     * @param {Object} params Function parameters
     * @param {string} params.siteId Site id of user
     * @param {string} params.name User name
     * @param {string} params.email User email
     * @param {string} params.userRole User's role
     * @param {string} params.site User's site
     * @param {string} params.userTitle User's occupation title
     * @param {boolean} params.forceUserTwoFactorAuth Force user to login with two factor auth. Requires them to setup 2FA
     * @returns {ResponseObject} Api response object
     */
    async createUser({name = "", email = "", userRole = "", userTitle = "", siteId = null, forceUserTwoFactorAuth = null}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let data = {
            FullName: name,
            Email: email,
            PhoneNumber: "",
            UserRole: userRole,
            Site: siteId,
            UserTitle: userTitle,
            ForceUserTwoFactorAuth: forceUserTwoFactorAuth
        }

        let config = {
            method: 'post',
            url: '/api/users',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })    
    }

    /**
     * @description Saves a specified user by id
     * @param {object} params
     * @param {string} params.id User id
     * @param {string} params.name User name
     * @param {string} params.email User email
     * @param {string} params.cellPhone User's cellPhone
     * @param {string} params.userTitle User's occupation title,
     * @param {boolean} params.userStatus Status of user
     * @param {boolean} params.userEnabled User alert notification status
     * @param {string} params.userRole Users type
     * @param {boolean} params.textEnabled User text enabled
     * @param {boolean} params.emailEnabled User email enabled
     * @param {object} params.settings User settings
     * @param {string} params.settings.theme User default theme settings
     * @param {string} params.settings.dashboardDefault User default mobile dashboard view settings
     * @param {string} params.settings.ratingsDefault User default mobile ratings view settings
     * @param {string} params.settings.measurement User unit measurement settings
     * @param {string} params.forceUserTwoFactorAuth Force user to login with two factor auth. Requires them to setup
     * @param {string} params.siteId User's site id
     * @returns {ResponseObject} Api response object
     */
    async saveUser({ id = null, name = null, email = null, cellPhone = null, userTitle = null, userStatus = null, userEnabled = null, emailEnabled = null, textEnabled = null, userRole = null, settings = null, siteId = null, forceUserTwoFactorAuth = null}) {
        let data = {
            FullName: name,
            Email: email,
            ...((cellPhone !== null) && {PhoneNumber: cellPhone}),
            ...((userRole !== null) && {IsActive: userStatus}),
            ...((userEnabled !== null) && {NotificationsEnabled: userEnabled}),
            ...((userTitle !== null) && {UserTitle: userTitle}),
            ...((userRole !== null) && {UserRole: userRole}),
            ...((emailEnabled !== null) && {EmailEnabled: emailEnabled}),
            ...((textEnabled !== null) && {TextEnabled: textEnabled}),
            ...((siteId !== null) && {Site: siteId}),
            ...((forceUserTwoFactorAuth !== null && {ForceUserTwoFactorAuth: forceUserTwoFactorAuth})),
            ...((settings !== null) && {Settings: {
                ...((settings.theme) && {Theme: settings.theme}),
                ...((settings.dashboardDefault) && {DashboardDefault: settings.dashboardDefault}),
                ...((settings.ratingsDefault) && {RatingsDefault: settings.ratingsDefault}),
                ...((settings.measurement) && {Measurement: settings.measurement})
            }})
        }
        
        let config = {
            method: 'put',
            url: `/api/users/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        }) 
    }

    /**
     * @description Saves a specified user by id
     * @param {object} params
     * @param {string} params.id User id
     * @param {string} params.name User name
     * @param {string} params.email User email
     * @param {string} params.cellPhone User's cellPhone,
     * @param {string} params.userTitle User's occupation title
     * @param {boolean} params.userStatus Status of user
     * @param {boolean} params.userEnabled User alert nnotifaction status
     * @param {string} params.userRole Users type
     * @param {string[]} params.items Array of user site, only the first element is used
     * @returns {ResponseObject} Api response object
     */
    async saveUserAdmin({ id = null, name = null, email = null, cellPhone = null, userTitle = null, userStatus = null, userEnabled = null, userRole = null, items = []}) {
        let data = {
            FullName: name,
            Email: email,
            PhoneNumber: cellPhone,
            IsActive: userStatus,
            NotificationsEnabled: userEnabled,
            UserRole: userRole,
            ...((userTitle !== null) && {UserTitle: userTitle}),
            ...((items.length !== 0) && {Site: items[0].guid})
        }

        let config = {
            method: 'put',
            url: `/api/users/${id}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
         
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Change user's password
     * @param {string} oldPassword 
     * @param {string} newPassword 
     * @returns {ResponseObject} Api response object
     */
    async changeUserPassword (oldPassword = "", newPassword = "") {
        let data = {
            OldPassword: oldPassword,
            NewPassword: newPassword
        }

        let config = {
            method: 'post',
            url: `/api/users/password`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
        
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Resend a users temporary password
     * @param {string} id Users id
     * @returns {ResponseObject} Api response object
     */
    async resendTemporaryPassword (id = "") {
        let config = {
            method: 'post',
            url: `/api/users/${id}/resendpassword/`,
            headers: { 
                'Content-Type': 'application/json'
            },
        }
        
        let res = await this.axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText, 
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get subscriptions by day
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchFilters.isActive Filter by isActive
     * @param {string[]} searchFilters.dayOfWeek Filter by day of week, defaults to all
     * @returns {ResponseObject} Api response object
     */
    async getScheduleMe({page = 0, order = "ASC", daysOfWeek = ['All']} = {}) {
        let searchFilters = {
            order,
            daysOfWeek: daysOfWeek,
        }
        
        let query = this.session.getQuery({page, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/users/me/schedule${"?" + query}`,
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get filtered user names
     * @param {string} siteId Site id to get users for 
     * @param {object} query Query to filter
     * @param {number} query.page Page number of data
     * @param {string} query.order Order of data
     * @param {string} query.search String to search 
     * @param {string} query.sortBy Field to sort by
     * @param {boolean} query.countOnly Only get the count of users back
     * @returns {ResponseObject} Api response object
     */
    async lookupUsers(siteId, {page = 0, order = 'ASC', search = "", sortBy = "", countOnly = false} = {}) {
        /**
         * Pseudo Code
         *  Initialize search filters
         *  Get query to filter by
         *  Set request config
         *  Get users
         */

        // Initialize search filters
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
        }

        // Get query to filter by
        let query = this.session.getQuery({page, siteId, searchFilters, countOnly})

        // Set request config
        let config = {
            method: 'get',
            url: `/api/users/lookup${"?" + query}`,
        }

        // Get users
        let res = await this.axios(config)
    
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Get a user's name by id
     * @param {string} id Id of user to get name for
     * @returns {ResponseObject} Api response object
     */
    async getUserName(id) {
        // Set request config
        let config = {
            method: 'get',
            url: `/api/users/${id}/name`,
        }
        
        // Get user
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    } 

    /**
     * Request 2FA
     * @param {string} type Type of 2FA to request
     * @returns {ResponseObject} Api response object
     */
    request2FA = async (type = "Authenticator") => {
        let config = {
            method: 'post',
            url: `/api/users/request2fa`,
            data: {
                AccessToken: this.session.getAccessToken()
            }
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Verify 2FA
     * @param {string} code Code to verify
     * @param {string} type Type of 2FA to verify
     * @returns {ResponseObject} Api response object
     */
    verify2FA = async (code = "", type = "Authenticator") => {
        let config = {
            method: 'post',
            url: `/api/users/verify2fa`,
            data: {
                AccessToken: this.session.getAccessToken(),
                Type: type,
                Code: code
            }
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * Disable 2FA
     * @param {string} type Type of 2FA to disable
     * @returns {ResponseObject} Api response object
     */
    disable2FA = async (type = "Authenticator") => {
        let config = {
            method: 'post',
            url: `/api/users/disable2fa`,
            data: {
                Type: type,
                AccessToken: this.session.getAccessToken()
            }
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })  
    }
}

export default Users